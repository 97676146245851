@font-face{
    font-family: 'NeueHaasUnicaPro-Light';
    src: url("../fonts/NeueHaasUnicaPro-Light.otf");
    src: url('../fonts/NeueHaasUnicaPro-Light.eot');
    src: url('../fonts/NeueHaasUnicaPro-Light.woff2') format('woff2'),
         url('../fonts/NeueHaasUnicaPro-Light.woff') format('woff'),
         url('../fonts/NeueHaasUnicaPro-Light.ttf') format('truetype'),
         ;
    font-weight: normal;
    font-style: normal;
}