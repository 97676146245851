/*** GLOBAL STYLES ***/

html {
	min-width: 320px;
	background: #fff;
}

body{
    min-width: $large-width;
	height: 100%;
    background: #000;
    color: #fff;
    font-family: 'NeueHaasUnicaPro-Light';
    font-size: 18px;
    line-height: 22px;
    @include large {
		min-width: 320px;
	}
    a, p{
		text-decoration: none;
        font-size: 18px;
        line-height: 22px;
        color: #fff;
	}
}

.global-wrap {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { //fix for ie 10-11
		height: 100%;
	}
	.main {
		flex: 1 0 auto;
    }
    .wrap {
        position: relative;
        width: $desktop-width;
        margin: 0 auto;
        padding: 0 20px;
        @include large {
            width: $desktop-width;
        }
        @include desktop{
            width: $tablet-width;
        }
        @include tablet{
            width: $mobile-width;
            padding: 0 10px;
        }
        @include mobile{
            width: 100%;
        }
    }
}


/* Top of page */

.top-of-page{
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 40px 0;

    .breadcrumbs{
        width: 336px;
        display: flex;
        flex-wrap: wrap;
        @include desktop{
            width: 240px;
        }
        @include tablet{
            width: 100%;
            margin: 0 0 20px 0;
        }
        a{
            position: relative;
            margin: 0 30px 0 0;
            &:after{
                content: ">";
                position: absolute;
                top: 2px;
                right: -20px;
                pointer-events: none;
            }
            &:last-of-type{
                pointer-events: none;
                text-decoration: underline;
                &:after{
                    content: none;
                }
            }
        }
    }

    .top-of-page-description{
        width: 648px;
        @include desktop{
            width: 485px;
        }
        @include tablet{
            width: 100%;
        }
    }

}

/* Categories on top page*/

.categories-top-page{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #fff;
    padding: 10px 0 20px 0;
    margin: 10px 0;
    @include tablet{
        display: none;
    }
    .link-container{
        width: 33%;
        a{
            display: inline-block;
            margin: 10px 0 10px 15px;
        }
    }
}

/* Contact info container */

.contact-info-container{
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #fff;
    padding: 20px 0 0 0;
    margin: 30px 0 0 0;
    .contact-info-container-title{
        width: 336px;
        margin: 0 0 20px 0;
        @include desktop{
            width: 240px;
        }
    }
    .right-col{
        width: 648px;
        display: flex;
        flex-wrap: wrap;
        @include desktop{
            width: 485px;
        }
        .street{
            width: 312px;
            margin: 0 20px 0 0;
            @include desktop{
                width: 220px;
            }
            @include tablet{
                width: 100%;
                margin: 0 0 20px 0;
            }
        }

        .feedback-container{
            width: 312px;
            padding: 0 0 0 0;
            @include desktop{
                width: 240px;
            }
            .email-container,
            .phone-container{
                display: flex;
                align-items: center;
                .title{
                    width: auto;
                }
                a{
                    display: inline-block;
                    margin: 0 0 0 10px;
                }
            }
        }

        .map{
            width: 100%;
            margin: 40px 0;
            iframe{
                width: 100%;
                height: 500px;
                @include tablet{
                    height: 300px;
                }
            }
        }

    }
}

/* Products list container */

.products-list-container{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #fff;
    &:last-of-type{
        border-bottom: none;
    }
    .products-container-title{
        margin: 20px 0 40px 0;
        width: 100%;
    }
    .products-card{
        position: relative;
        width: 164px;
        margin: 0 0 15px 0;
        @include desktop{
            width: 145px;
        }
        @include tablet{
            width: 115px;
        }
        @include mobile{
            width: 33%;
        }
        &:hover{
            .img-container{
                opacity: 0;
                visibility: hidden;
            }
            .product-title{
                opacity: 1;
                visibility: visible;
            }
        }
        .img-container{
            width: 100%;
            padding-bottom: 80%;
            position: relative;
            overflow: hidden;
            transition: 0.4s;
            img{
                object-fit: cover;
                position: absolute;
                width: auto;
                height: auto;
                min-width: 100%;
                min-height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .product-title{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            visibility: hidden;
            transition: 0.4s;
            @include mobile{
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
}

/* Slider */

.slider-container{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #fff;
    &:last-of-type{
        border-bottom: none;
    }
    .slider{
        opacity: 0;
        visibility: hidden;
        transition: opacity 1s ease;
        -webkit-transition: opacity 1s ease;
        &.slick-initialized {
            visibility: visible;
            opacity: 1;    
        }
        &.single-element{
            opacity: 1;
            visibility: visible;
        }
        .img-container{
            position: relative;
            overflow: hidden;
            padding-bottom: 60%;
            img{
                object-fit: cover;
                position: absolute;
                width: auto;
                height: auto;
                min-width: 100%;
                min-height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .slick-arrow{
            position: absolute;
            top: calc(50% - 15px);
            width: 32px;
            height: 32px;
            &.slick-next{
                right: -50px;
                font-size: 0;
                background: url("../img/svg-icons/arrow-right.svg") no-repeat;
                background-size: 100%;
            }
            &.slick-prev{
                left: -50px;
                font-size: 0;
                background: url("../img/svg-icons/arrow-left.svg") no-repeat;
                background-size: 100%;
            }
        }
    }
    .slider-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 0 0;
        .slider-count{
            display: none;
        }
    }
}


/*** HEADER ***/

header{
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 0 15px 0;
    border-bottom: 1px solid #fff;
    @include tablet{
        padding: 30px 0 15px 0;
        align-items: center;
    }
    .logo{
        img{
            width: 80px;
            height: 42px;
        }
    }
    .open-menu-btn{
        display: none;
        @include tablet{
            display: block;
        }
        svg{
            fill: #fff;
            width: 20px;
            height: 25px;
        }
    }
    .menu{
        display: flex;
        @include tablet{
            z-index: 3;
            width: 85%;
            position: absolute;
            top: 116px;
            right: 0;
            background: #000;
            flex-direction: column;
            transition: 0.2s;
            opacity: 0;
            visibility: hidden;
            &.active{
                opacity: 1;
                visibility: visible;
                top: 100px;
            }
        }
        a{
            display: block;
            padding: 15px 20px;
            &:last-of-type{
                padding: 15px 0 15px 20px;
                @include desktop{
                    padding: 15px 0 15px 15px;
                }
            }
            @include desktop{
                padding: 15px 15px;
            }
            @include tablet{
                border-bottom: 1px solid white;
            }
            &.active{
                text-decoration: underline;
            }
        }
    }
}


/*** FOOTER ***/

footer{

    .footer-main-container{
        display: flex;
        flex-wrap: wrap;
        padding: 15px 0 30px 0;
        border-top: 1px solid #fff;
        @include tablet{
            padding: 15px 0 0 0;
        }

        .feedback-container{
            width: 312px;
            margin: 0 24px 20px 0;
            @include desktop{
                width: 220px;
                margin: 0 20px 20px 0;
            }
            .email-container,
            .phone-container{
                display: flex;
                align-items: center;
                a{
                    display: inline-block;
                    margin: 0 0 0 10px;
                }
            }
        }

        .menu{
            width: 312px;
            margin: 0 24px 20px 0;
            display: flex;
            flex-direction: column;
            @include desktop{
                width: 220px;
                margin: 0 20px 20px 0;
            }
            .menu-item{
                display: inline-block;
                &.active{
                    text-decoration: underline;
                }
            }
        }

        .search-form{
            width: 312px;
            @include desktop{
                width: 247px;
            }
            @include tablet{
                width: 100%;
                border-top: 1px solid #fff;
            }
            input{
                width: 100%;
                font-size: 18px;
                line-height: 22px;
                background: transparent;
                border-bottom: 1px solid #fff;
                padding: 0 0 10px 0;
                @include tablet{
                    padding: 10px 0;
                }
                &::placeholder{
                    color: #fff;
				}
				&::-moz-placeholder{
					color: #fff;
				}
            }
        }

    }

    .description-container{
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid white;
        padding: 15px 0 30px 0;
        .title{
            width: 336px;
            padding: 0 20px 15px 0;
            @include desktop{
                width: 240px;
            }
            @include tablet{
                width: 100%;
            }
        }
        .description{
            width: 648px;
            @include desktop{
                width: 485px;
            }
            @include tablet{
                width: 100%;
            }
        }
    }

    .instagram-gallery{
        border-top: 1px solid #fff;
        .title{
            display: inline-block;
            padding: 15px 0 0 0;
        }
        .gallery-container{
            margin: 15px 0 0 0;
            flex-wrap: wrap;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .img-container{
                display: block;
                width: 215px;
                height: 215px;
                margin: 0 40px 30px 0;
                position: relative;
                overflow: hidden;
                &:last-of-type{
                    margin: 0 0 30px 0;
                }
                @include desktop{
                    width: 170px;
                    height: 170px;
                    margin: 0 15px 30px 0;
                }
                @include tablet{
                    width: 215px;
                    height: 215px;
                    margin: 0 30px 30px 0;
                    &:nth-of-type(2){
                        margin: 0 0 30px 0;
                    }
                }
                @include mobile{
                    width: 100%;
                    padding-bottom: 100%;
                    margin: 0 0 15px 0;
                }
                img{
                    object-fit: cover;
                    position: absolute;
                    width: auto;
                    height: auto;
                    min-width: 100%;
                    min-height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

}


/*** PRODUCTS ***/

.products-page{

    .products-categories-list{
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0 20px 0;
        .products-categories-card{
            display: block;
            width: 477px;
            border-bottom: 1px solid #fff;
            padding: 20px 0 0 0;
            margin: 0 30px 0 0;
            &:nth-of-type(2n){
                margin: 0 0 0 0;
            }
            @include desktop{
                width: 348px;
            }
            @include tablet{
                width: 100%;
                margin: 0 0 0 0;
                &:nth-of-type(3n){
                    margin: 0 0 0 0;
                }
            }
            .img-container{
                width: 100%;
                padding-bottom: 60%;
                position: relative;
                overflow: hidden;
                img{
                    object-fit: cover;
                    position: absolute;
                    width: auto;
                    height: auto;
                    min-width: 100%;
                    min-height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .products-name{
                font-size: 20px;
                line-height: 1.4;
                margin: 20px 0 15px 0;
            }
        }
    }

    .products-list-container{
        @include desktop{
            display: none;
        }
    }

}


/*** PRODUCT PAGE ***/

.product-page{

    .slider-container{
        border-bottom: none;
    }

    .product-description{
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 100px 0;
        border-bottom: 1px solid #fff;
        @include tablet{
            padding: 0 0 20px 0;
        }
        .left-col{
            width: 336px;
            @include desktop{
                width: 100%;
            }
            .name{
                margin: 0 0 10px 0;
            }
            .id{
                margin: 0 0 20px 0;
            }
        }
        .right-col{
            width: 648px;
            display: flex;
            flex-wrap: wrap;
            @include desktop{
                width: 100%;
            }
            .description{
                width: 50%;
                padding: 0 15px 0 0;
                @include tablet{
                    width: 100%;
                    padding: 0 0 0 0;
                }
                .title{
                    margin: 0 0 10px 0;
                }
            }
            .specifications{
                width: 50%;
                padding: 0 0 0 15px;
                @include tablet{
                    width: 100%;
                    padding: 20px 0 0 0;
                }
                ul{
                    li{
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
                a{
                    display: inline-block;
                    opacity: 0.8;
                    margin: 25px 0 0 0;
                }
            }
        }
    }

}


/*** BESPOKE ***/
/*** PROJECTS ***/
/*** DESIGNERS ***/

.bespoke-page,
.project-page,
.designers-page{

    .slider-container{
        border-bottom: none;
    }

    .bespoke-list,
    .project-list,
    .designers-list{
        display: flex;
        flex-wrap: wrap;
        .bespoke-card,
        .project-card,
        .designer-card{
            display: block;
            width: 312px;
            border-top: 1px solid #fff;
            padding: 20px 0 0 0;
            margin: 0 24px 0 0;
            &:nth-of-type(3n){
                margin: 0 0 0 0;
            }
            @include desktop{
                width: 351px;
                &:nth-of-type(2n){
                    margin: 0 0 0 0;
                }
                &:nth-of-type(3n){
                    margin: 0 24px 0 0;
                }
            }
            @include tablet{
                width: 100%;
                margin: 0 0 0 0;
                &:nth-of-type(3n){
                    margin: 0 0 0 0;
                }
            }
            .img-container{
                width: 100%;
                padding-bottom: 100%;
                position: relative;
                overflow: hidden;
                img{
                    object-fit: cover;
                    position: absolute;
                    width: auto;
                    height: auto;
                    min-width: 100%;
                    min-height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .bespoke-name,
            .project-name,
            .designer-name{
                font-size: 20px;
                line-height: 1.4;
                margin: 20px 0 15px 0;
            }
        }
    }

    .bespoke-description,
    .project-description,
    .designer-description{
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;

        .left-col{
            width: 336px;
            @include desktop{
                width: 100%;
            }
            .project-name{
                margin: 0 0 20px 0;
            }
            .bespoke-name,
            .designer-name{
                margin: 0 0 20px 0;
            }

            .img-container{
                position: relative;
                overflow: hidden;
                width: 215px;
                height: 215px;
                @include desktop{
                    width: 300px;
                    height: 300px;
                }
                img{
                    object-fit: cover;
                    position: absolute;
                    width: auto;
                    height: auto;
                    min-width: 100%;
                    min-height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            a{
                display: block;
                margin: 30px 0 10px 0;
            }
           
        }

        .right-col{
            width: 648px;
            webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
            -webkit-column-gap: 20px;
            column-gap: 20px;
            @include desktop{
                margin: 20px 0 0 0;
                width: 100%;
                webkit-column-count: 1;
                -moz-column-count: 1;
                column-count: 1;
                -webkit-column-gap: 0;
                column-gap: 0;
            }
            p{
                text-indent: 20px;
            }
            .project-list{
                display: flex;
                flex-direction: column;
                h2{
                    font-size: 18px;
                    line-height: 22px;
                    margin: 20px 0 10px 0;
                }
                a{
                    margin: 0 0 10px 0;
                }
            }
        }

    }

    .designer-product-list{
        padding: 40px 0 0 0;
        border-top: 1px solid #ffff;
        display: flex;
        flex-wrap: wrap;
        .designer-product-list-title{
            width: 100%;
        }
        .product-card{
            width: 25%;
            margin: 40px 0 60px 0;
            @include tablet{
                width: 33%;
            }
            @include mobile{
                width: 50%;
            }
            .img-container{
                margin: auto;
                width: 70%;
                padding-bottom: 50%;
                position: relative;
                overflow: hidden;
                img{
                    object-fit: cover;
                    position: absolute;
                    width: auto;
                    height: auto;
                    min-width: 100%;
                    min-height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

}


/*** ABOUT US ***/

.about-us-page{
  
    .slider-container{
        border-bottom: none;
    }

    .info-container{
        display: flex;
        flex-wrap: wrap;
        padding: 25px 0 25px 0;
        border-top: 1px solid #fff;
        .info-container-title{
            width: 336px;
            padding: 0 0 20px 0;
        }
        .info-container-content-description{
            width: 648px;
            webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
            -webkit-column-gap: 20px;
            column-gap: 20px;
            @include desktop{
                width: 100%;
            }
            @include tablet{
                webkit-column-count: 1;
                -moz-column-count: 1;
                column-count: 1;
                -webkit-column-gap: 0;
                column-gap: 0;
            }
            p{
                text-indent: 20px;
            }
        }
        .info-container-content-links{
            display: flex;
            flex-direction: column;
            width: 648px;
            @include desktop{
                width: 100%;
            }
            a{
                display: inline-block;
                margin: 0 0 8px 0;

            }
        }
    }

}


/*** CONTACT ***/

.contact-page{

    form{
        padding: 20px 0 20px 336px;
        @include desktop{
            padding: 20px 0 20px 240px;
        }
        @include tablet{
            padding: 20px 0 20px 0;
        }
        .input-field{
            width: 100%;
            margin: 0 0 20px 0;
            input,
            textarea{
                width: 100%;
                padding: 5px 0;
                font-size: 18px;
                line-height: 22px;
                background: transparent;
                border-bottom: 1px solid #fff;
                &::placeholder{
                    color: #fff;
				}
				&::-moz-placeholder{
					color: #fff;
				}
            }
            textarea{
                height: 160px;
            }
            .error-msg{
                display: none;
                font-size: 18px;
                margin: 5px 0 0 0;
            }
        }
        button{
            font-size: 18px;
            background: transparent;
            border: 1px solid #fff;
            width: auto;
            margin: 35px 0 0 0;
            padding: 6px 18px;
        }
    }

}
