//config
@import 'partials/_fonts.scss';
@import 'partials/_vars.scss';
@import 'partials/_mixins.scss';
@import 'partials/_sprite.scss';

//libs
@import 'partials/slick.scss';

//reset
@import 'partials/reset.scss';

//common
@import 'styles.scss';
